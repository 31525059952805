<template>
  <div class="input_container">
    <label>
      <p>{{ title }}</p>
      <input
        :type="type"
        :value="value"
        v-on:input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        @blur="$emit('blur')"
      />
    </label>
    <p class="input-error">{{ errorMessage ? errorMessage : "" }}</p>
  </div>
</template>

<script>
export default {
  emits: ["input", "blur"],
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    title: {
      type: String
    },
    errorMessage: {
      type: String
    }
  }
};
</script>

<style scoped>
.input-error {
  color: rgb(234, 0, 0);
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  bottom: -15px;
}

input {
  border: none;
  background: transparent;
  width: 100%;
  height: 40px;
  border: 1px solid #c4c4c4;
  background-color: white;
  border-radius: 5px;
  padding: 0 10px;
}

label {
  width: 100%;
}

label p {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}

.input_container {
  width: 100%;
  position: relative;
}
</style>
