<template>
  <div>
    <AddNewLocationModal
      :states="states"
      v-if="addingLocation == true"
      @close-modal="addingLocation = false"
      @confirm-modal="openSuccesModal"
    />
    <!-- new plaza modal -->
    <AddNewEMP
      :title="EMP"
      :states="states"
      :selectedState="state"
      :EMPList="EMPList"
      v-if="addingEMP == true"
      @close-modal="addingEMP = false"
      @confirm-modal="addingEMP = false"
    />

    <!-- Add new estate modal -->

    <LocationConfirmModal
      v-if="showSuccesModal"
      @close="showSuccesModal = false"
    />
    <div class="container">
      <p class="input-error" v-show="errorMessage.form">
        {{ this.errorMessage.form }}
      </p>
      <div class="container__head">
        <h3>Property Identifier</h3>
      </div>

      <div class="form">
        <!-- Title -->
        <FormInput
          placeholder="E.g Newly built 3 bedroom flat"
          title="Title"
          :value="propertyTitle"
          @input="propertyTitle = $event"
          :errorMessage="errorMessage.title"
          @blur="validateTitleInput"
        />

        <!-- Address -->
        <FormInput
          title="Address"
          placeholder="House Address"
          :value="propertyAddress"
          @input="propertyAddress = $event"
          :errorMessage="errorMessage.propertyAddress"
          @blur="validatePropertyAddress"
        />

        <!-- state -->
        <FormSelect
          title="State"
          @select-option="state = $event"
          :value="state"
          :errorMessage="errorMessage.state"
          @blur="validateState"
        >
          <option disabled selected value="">Select state</option>

          <option
            v-for="option in states"
            :key="option.id"
            :value="option.name"
            >{{ option.name }}</option
          >
        </FormSelect>

        <FormSelect
          title="Is this in an Estate, Mall, Plaza?"
          @select-option="EMP = $event"
          :value="EMP"
        >
          <option value="none">None</option>

          <option v-for="item in EMPList" :key="item.id" :value="item.name">{{
            item.name
          }}</option>
        </FormSelect>

        <!-- Estate mall plaza  -->
        <CustomSelectSearch
          v-if="showEstateSelection"
          :title="EMP"
          @select-option="e => (selectedEMPValue = e)"
          @blur="validateEMP"
          :options="selectedEMPOptions || []"
          :errorMessage="this.errorMessage.selectedEMPValue"
          @add="addEMP"
        >
        </CustomSelectSearch>

        <!-- Property Type  -->

        <FormSelect
          :options="propertyTypes"
          title="Property Type"
          @select-option="propertyType = $event"
          @blur="validateEMP"
          :value="propertyType"
          :errorMessage="this.errorMessage.EMP"
        >
          <option disabled selected value="null">Select at least 1 type</option>

          <option
            v-for="option in propertyTypes"
            :value="option.type"
            :key="option.value"
            >{{ option.type }}</option
          >
        </FormSelect>

        <!-- Property subtype -->
        <FormSelect
          title="Property Subtype"
          @select-option="propertySubType = $event"
          @blur="validatePropertySubType"
          :value="propertySubType"
          :errorMessage="errorMessage.propertySubType"
        >
          <option disabled selected value="">Select property subtype</option>
          <option
            v-for="option in propertySubTypes"
            :value="option.subtype"
            :key="option.id"
            >{{ option.subtype }}</option
          >
        </FormSelect>

        <!-- Number of bedrooms -->

        <FormInput
          v-if="propertyType == 'Residential'"
          title="Bedrooms"
          placeholder="5"
          :value="noOfBedroom"
          @input="noOfBedroom = $event"
          :errorMessage="errorMessage.bedrooms"
          @blur="validateBedrooms"
        />

        <CustomSelectSearch
          v-if="showLocationSelection"
          title="Location"
          @select-option="e => (city = e)"
          @blur="validateCity"
          :errorMessage="errorMessage.city"
          :options="cities || []"
          @add="addingLocation = true"
        >
        </CustomSelectSearch>
      </div>
      <div class="btn-container">
        <button class="submit-btn" @click="submitForm">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AddNewLocationModal from "../components/AddNewLocationModal.vue";
import FormInput from "../components/FormInput.vue";
import FormSelect from "../components/FormSelect.vue";
import properties from "../services/properties";
import LocationConfirmModal from "../components/LocationConfirmModal.vue";
import CustomSelectSearch from "../components/CustomSelectSearch.vue";
import getLocation from "../services/getLocation";
import addLocation from "../services/addLocation";
import AddNewEMP from "../components/AddNewEMP.vue";
export default {
  components: {
    FormInput,
    FormSelect,
    AddNewLocationModal,
    LocationConfirmModal,
    CustomSelectSearch,
    AddNewEMP
  },
  data() {
    return {
      propertyTitle: "",
      propertyAddress: "",
      propertyUse: "",
      noOfBedroom: "0",
      propertyUses: ["Yes", "No"],
      EMP: "",

      //EMPID: null,
      EMPList: [],
      selectedEMPOptions: [],
      selectedEMPValue: "none",
      showLocationSelection: false,
      showEstateSelection: false,
      propertyTypes: [],
      propertySubTypes: [],
      propertySubType: "",
      propertyType: "",
      state: "",
      cities: [],
      city: "",
      addingLocation: null,
      addingEMP: false,
      addingEstate: null,
      showSuccesModal: false,
      customValue: "",
      states: [],
      bedroomOptions: [],
      type: 1,
      subtype: 6,
      bedrooms: 0,
      denomination: "naira",
      businesstype: 1,
      errorMessage: {
        title: "",
        propertyAddress: "",
        propertyUse: "",
        propertyType: "",
        bedrooms: "",
        selectedEMPValue: "",
        propertySubType: "",
        leaseOrRent: "",
        status: "",
        state: "",
        city: "",
        propertyValue: "",
        form: "", 
        subscription: ""
      }
    };
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
  },
  methods: {
    addEMP() {
      if (!this.state) {
        this.$toast.error("Please Select State");
      } else {
        this.addingEMP = true;
      }
    },

    submitForm() {
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to add property");
      }
      if (this.isFormValid) {
        const body = {
          title: this.propertyTitle,
          type: this.propertyTypeId,
          subtype: this.propertySubTypeId,
          bedrooms: this.noOfBedroom || "",
          stateid: this.stateId,
          cityid: this.cityId,
          denomination: "naira",
          businesstype: 1,
          address: this.propertyAddress,
          estateorplazamallothersid: this.EMPID || "",
          categoryid: this.EMPID || 1,
          userid: this.$store.state.user.id
        };
        properties
          .registerIdentifiedProperty(JSON.stringify(body))
          .then(data => {
            if (data.error) {
              this.$toast.error("Unable to register property");
              console.error(data.error);
              return;
            }

            this.clearForm();
            this.showSuccesModal = true;
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$toast.error("PLEASE MAKE SURE ALL INPUTS ARE FILLED AND CORRECT");
      }
    },

    closeLocationModal() {
      return true;
    },
    clearForm() {
      this.propertyTitle = "";
      this.propertyAddress = "";
      this.propertyType = "";
      this.propertySubType = "";
      this.state = "";
      this.propertyValue = "";
      this.propertyStatus = "";
      this.description = "";
    },

    getPropertyTypes() {
      //fetch properties
      properties
        .fetchPropertyType()
        .then(data => {
          //fetch subproperty on first load
          this.propertyTypes = data.propertytypes;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getPropertySubTypes(id) {
      properties
        .fetchPropertySubType(id)
        .then(data => {
          this.propertySubTypes = data.propertysubtypes;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getStates() {
      properties.fetchStates().then(data => {
        this.states = data.states;
      });
    },

    openSuccesModal() {
      this.addingLocation = false;
      this.addingEstate = false;
      this.showSuccesModal = true;
    },

    validateTitleInput() {
      if (this.propertyTitle.trim() === "") {
        this.errorMessage.title = " Please input title";
        return false;
      }
      this.errorMessage.title = "";
      return true;
    },
    validateEMP() {
      if (this.EMP != "" && this.selectedEMPValue.trim() === "") {
        this.errorMessage.selectedEMPValue = `Please select the name of the ${this.EMP} `;
        return false;
      }

      this.errorMessage.selectedEMPValue = "";
      return true;
    },

    validateBedrooms() {
      if (this.noOfBedroom.trim() === "") {
        this.errorMessage.bedrooms = "Please input Number of Bedrooms";
        return false;
      }

      if (isNaN(this.noOfBedroom)) {
        this.errorMessage.bedrooms = "Number of bedrooms must be a number";
        return false;
      }

      this.errorMessage.bedrooms = "";
      return true;
    },

    validatePropertyType() {
      if (this.propertyType === "") {
        this.errorMessage.propertyType = "Please select a property type";
        return false;
      }

      this.errorMessage.propertyType = "";
      return true;
    },

    validatePropertyUse() {
      if (this.propertyUse === "") {
        this.errorMessage.propertyUse =
          "Please select the use of this property";
        return false;
      }
      this.errorMessage.propertyUse = "";
      return true;
    },

    validatePropertyAddress() {
      if (this.propertyAddress === "") {
        this.errorMessage.propertyAddress = "Please input property address";
        return false;
      }
      this.errorMessage.propertyAddress = "";
      return true;
    },

    validatePropertySubType() {
      if (this.propertySubType === "") {
        this.errorMessage.propertySubType = "Please select a property Sub type";
        return false;
      }

      this.errorMessage.propertySubType = "";
      return true;
    },

    validateState() {
      if (this.state == "") {
        this.errorMessage.state = "Please select state";

        return false;
      }

      this.errorMessage.state = "";
      return true;
    },

    validateCity() {
      if (this.city == "") {
        this.errorMessage.city = "Please select city";
        return false;
      }
      this.errorMessage.city = "";

      return true;
    },

    getCitiesByState(stateId) {
      this.cities = [];
      this.city = "";
      getLocation.getCities(stateId).then(data => {
        this.cities = data.cities;
      });
    },

    getEPMList() {
      addLocation
        .getEPM()
        .then(data => {
          this.EMPList = data.mapper;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },

  mounted() {
    this.getPropertyTypes();
    this.getStates();
    this.getEPMList();
  },
  watch: {
    propertyType(newType) {
      if (newType) {
        const typeId = this.propertyTypes.find(el => el.type === newType)["id"];
        this.getPropertySubTypes(typeId);
      } else {
        this.propertySubTypes = [];
      }
    },
    state(newLocation) {
      if (newLocation) {
        const locationId = this.states.find(el => el.name === newLocation)[
          "id"
        ];

        this.getCitiesByState(locationId);
        this.showLocationSelection = true;
      } else {
        this.cities = [];
        this.showLocationSelection = false;
      }
    },
    EMP(newVal) {
      this.showEstateSelection = newVal != "none" ? true : false;

      let empID = this.EMPList.filter(item => item.name === newVal)[0]?.id;

      if (empID) {
        addLocation.getEPMList(empID).then(data => {
          console.log(data);

          this.selectedEMPOptions = data.data;
        });
      }
    }
  },
  computed: {
    EMPID() {
      if (this.EMP != "") {
        return this.EMPList.filter(item => item.name === this.EMP)[0].id;
      }

      return "";
    },
    propertyTypeId() {
      return (
        this.propertyTypes.find(el => el.type === this.propertyType).id || ""
      );
    },
    propertySubTypeId() {
      return (
        this.propertySubTypes.find(el => el.subtype === this.propertySubType)
          .id || ""
      );
    },
    stateId() {
      return this.states.find(el => el.name === this.state).id || "";
    },
    cityId() {
      const id = this.cities.filter(el => el.name == this.city)[0].id;
      return id;
    },
    isFormValid() {
      return (
        this.validateTitleInput() &&
        this.validatePropertyAddress() &&
        this.validateEMP() &&
        this.validateBedrooms() &&
        this.validatePropertyType() &&
        this.validatePropertySubType() &&
        this.validateState() &&
        this.validateCity()
      );
    }
  }
};
</script>

<style scoped>
.input-error {
  color: rgb(234, 0, 0);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
  padding: 1rem 2rem 0 2rem;

  /* position: absolute; */
  /* bottom: -20px; */
}

.submit-btn {
  padding: 1rem 2rem;
  background-color: var(--green-color);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  margin: auto;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.container__head {
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #cbcbcb;
}
.form-radios {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
h3 {
  padding-top: 1.5rem;
  padding-left: 1rem;
  color: #87c76e;
}
.container {
  background-color: white;
  border-radius: 10px;
  margin-top: 2rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
}

@media (min-width: 414px) {
  .form {
    padding: 1.5rem;
  }

  .form-radios {
    gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .form {
    grid-template-columns: 1fr 1fr;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

p {
  margin: 0;
}
</style>
