<script>
import TheModal from "./UI/TheModal.vue";

export default {
  emits: ["close"],
  data() {
    return {
      formSubmissionState: null,
      state: null,
      location: ""
    };
  },
  props: ["states"],
  components: { TheModal },
  watch: {
    states() {}
  }
};
</script>
<template>
  <TheModal>
    <div class="location-confirm-modal">
      <div class="modal-body">
        <div class="success-icon-container">
          <div class="success-icon">
            <svg
              width="75"
              height="60"
              viewBox="0 0 75 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.98633 37.9923L26.8187 57.1398L73.3795 1.61182"
                stroke="#2FC643"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>

        <p>Thank you for contributing to sustainable living</p>

        <div class="btn-container">
          <button class="btn submit-btn" @click.prevent="$emit('close')">
            Close
          </button>
        </div>
      </div>
    </div>
  </TheModal>
</template>
<style scoped>
.location-confirm-modal {
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

h3 {
  color: #87c76e;
  font-size: 1.5rem;
}

.modal-body {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 2rem;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  /* margin: auto; */
  padding: 1rem 2rem;
}

.submit-btn {
  background-color: var(--green-color);
}

.close-btn {
  background-color: transparent;
  border: 1px solid #c4c4c4;
  color: #000000;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;
}

.modal-body p {
  text-align: center;
}
.form-confirm p {
  text-align: center;
}

.confirm-location {
  background: #efefef;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 2rem 1rem;
  margin-top: 1rem;
}

.confirm-location p {
  text-align: center;
  font-weight: bolder;
}

.success-icon {
  border: 1px solid #87c76e;
  display: inline-block;
  padding: 1.5rem 1.2rem;
  border-radius: 100%;
}

.success-icon-container {
  display: grid;
  place-items: center;
  margin-bottom: 1rem;
}

@media screen and (min-width) {
  .form-body form > * + * {
    margin-top: 1rem;
  }
}
</style>
