<template>
  <div class="input_container">
    <label>
      <p>{{ title }}</p>
      <div class="select-container">
        <select
          @input="$emit('select-option', $event.target.value)"
          @blur="$emit('blur')"
          :value="value"
        >
          <slot> </slot>
        </select>
      </div>
    </label>
    <p class="input-error">{{ errorMessage ? errorMessage : "" }}</p>
  </div>
</template>

<script>
export default {
  emits: ["blur", "input"],
  props: {
    title: {
      type: String
    },
    value: { type: String },
    errorMessage: {
      type: String
    }
  }
};
</script>

<style scoped>
.input_container {
  width: 100%;
  /* padding: 0 10px; */
  position: relative;
}

.input-error {
  color: rgb(234, 0, 0);
  font-size: 8px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: -15px;
}

label {
  width: 100%;
}

.select-container {
  padding: 0 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: white;
}

select {
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

p {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}
</style>
