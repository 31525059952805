import client from "./requestclient";

const addLocation = {
  //EPM: estate mall plaza
  getEPM: async () => {
    return await client({
      URL_PATH: "fetchestateplazamallmapper",
      method: "GET"
    });
  },

  //this will return the list of either estates, mall, or plazaz based on the ID
  getEPMList: async id => {
    return await client({
      URL_PATH: "fetchestateplazamallbymapperid/" + id,
      method: "GET"
    });
  },

  //
  async registerEMP(EMP) {
    return await client({
      URL_PATH: "createorplazaormallorothers",
      method: "POST",
      body: EMP
    });
  },
  async registerLocation(body) {
    return await client({
      URL_PATH: "processaddcustomlocation",
      method: "POST",
      body: body
    });
  }
};

export default addLocation;
