import client from "./requestclient";

const getLocation = {
  getCountries: async () => {
    return await client({ URL_PATH: "fetchcountries", method: "GET" });
  },
  getStates: async id => {
    return await client({
      URL_PATH: `fetchstatesbycountryid/${id}`,
      method: "GET"
    });
  },
  getCities: async id => {
    return await client({
      URL_PATH: `fetchcitiesbystateid/${id}`,
      method: "GET"
    });
  },
  getAllCities: async () => {
    return await client({
      URL_PATH: "fetchcitiesinnigeria",
      method: "GET"
    });
  }
};

export default getLocation;
