<template>
  <div class="input_container custom-select" ref="inputContainer">
    <label>
      <p>{{ title }}</p>
      <div class="select-container">
        <input
          type="search"
          :placeholder="`Select ${title}`"
          :value="value"
          @input="e => (value = e.target.value)"
          @focus="openOptions"
          @blur="$emit('blur')"
        />
      </div>
    </label>
    <p class="input-error">{{ errorMessage ? errorMessage : "" }}</p>

    <div class="options-body" v-if="showOptions" ref="optionsBody">
      <div class="options">
        <ul v-if="!filteredOptions">
          <li
            v-for="option in options"
            :key="option.id"
            @click.stop="selectOption(option)"
          >
            {{ option.name }}
          </li>
        </ul>

        <ul v-else>
          <li
            v-for="option in filteredOptions"
            :key="option.id"
            @click.stop="selectOption(option)"
          >
            {{ option.name }}
          </li>
        </ul>
      </div>

      <button class="add-new" @click="$emit('add')">
        Add New {{ title }} Here
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["blur", "input", "select-option"],
  props: {
    title: {
      type: String
    },
    // value: { type: String },
    errorMessage: {
      type: String
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      showOptions: false,
      filteredOptions: null,
      searchInput: "",
      value: "",
      lastSelected: ""
    };
  },
  methods: {
    openOptions() {
      window.addEventListener("click", e => {
        if (!e.target.closest(".custom-select")) {
          this.closeOptions();
        }
      });
      this.showOptions = true;
    },
    closeOptions() {
      this.showOptions = false;
    },

    inputFocusHandler(e) {
      console.log(e.target);
    },
    selectOption(option) {
      this.closeOptions();

      //set value to the name of the option
      this.value = option.name;

      //also emit this value to the parent component
      this.$emit("select-option", option.name);
      this.$emit("blur");
    },
    searchHandler(str) {
      const pattern = new RegExp(str, "gi");
      this.filteredOptions = this.options.filter(opt => pattern.test(opt.name));
    }
  },

  watch: {
    options() {
      this.$emit("select-option", "");
      this.value = "";
    },
    value(newVal) {
      if (newVal == "") {
        this.$emit("select-option", "");
      }
      this.searchHandler(newVal);
    }
  }
};
</script>

<style scoped>
option {
  display: none;
}

.options-body {
  position: absolute;
  top: 0;
  margin-top: 4.5rem;
  z-index: 10;
  left: 0;
  right: 0;
  max-height: 250px;
  background: #ffffff;
  box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
  padding-top: 5px;
}

.options-body input[type="search"] {
  background: #efefef;
  border: 1px solid #c4c4c4;
  display: block;
  border-radius: 5px;
  width: 95%;
  margin: 0 auto;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  margin-bottom: 1rem;
}

.options-body .options {
}

.options ul {
  list-style: none;
}

.options li {
  padding: 5px 1rem;
  cursor: pointer;
}

.options ul li:hover {
  background: #efefef;
}

.options-body .add-new {
  background: #083bf1;
  position: sticky;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  margin-top: 1rem;
  padding: 0.5rem 0;
}

.input_container {
  width: 100%;
  /* padding: 0 10px; */
  position: relative;
}

.input-error {
  color: rgb(234, 0, 0);
  font-size: 8px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: -15px;
}

label {
  width: 100%;
  text-transform: capitalize;
}

.select-container {
  padding: 0 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: white;
}

input[type="search"] {
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

p {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}
</style>
