<script>
import FormInput from "./FormInput.vue";
import FormSelect from "./FormSelect.vue";
import TheModal from "./UI/TheModal.vue";
import getLocation from "../services/getLocation";
import addLocation from "../services/addLocation";

export default {
  emits: ["confirm-modal", "close-modal"],
  data() {
    return {
      formSubmissionState: null,
      cities: null,
      city: null,
      newEMP: ""
    };
  },
  props: ["states", "title", "selectedState", "EMPList"],

  methods: {
    confirmEMP() {
      console.log(this.cityId);
      if (this.newEMP == "" || this.city == null) {
        this.$toast.error(
          "Please input new " + this.title + " name and select city"
        );
      } else {
        this.formSubmissionState = "pending";
      }
    },

    submitNewEMP() {
      let body = {
        categoryid: this.categoryId,
        name: this.newEMP,
        cityid: this.cityId
      };

      addLocation
        .registerEMP(JSON.stringify(body))
        .then(data => {
          if (data.error) {
            console.error(data.error);
            return;
          }
          this.formSubmissionState = null;
          this.$emit("close-modal");

          return data;
        })
        .catch(err => {
          console.error(err);
        });
    },

    async getCityId(stateId) {
      const res = await getLocation.getCities(stateId);
      const result = await res;

      console.log(result);
    }
  },
  components: { TheModal, FormSelect, FormInput },
  watch: {
    states() {}
  },
  mounted() {
    console.log(this.EMPList);

    getLocation.getCities(this.stateId).then(data => {
      console.log(data);
      this.cities = data.cities;
    });
  },
  computed: {
    stateId() {
      const id = this.states.filter(el => el.name == this.selectedState)[0].id;
      return id;
    },
    cityId() {
      const id = this.cities.filter(el => el.name == this.city)[0].id;
      return id;
    },
    categoryId() {
      const id = this.EMPList.filter(el => el.name == this.title)[0].id;
      return id;
    }
  }
};
</script>
<template>
  <TheModal>
    <div class="new-location-modal">
      <div class="container-head">
        <h3>Add new {{ title }}</h3>
      </div>

      <div class="modal-body">
        <div class="form-body" v-if="formSubmissionState == null">
          <form>
            <FormSelect title="City" @select-option="city = $event">
              <option disabled selected value="">Select city</option>

              <option
                v-for="option in cities"
                :key="option.id"
                :value="option.name"
                >{{ option.name }}</option
              >
            </FormSelect>
            <FormInput :title="title" @input="newEMP = $event" />

            <div class="btn-container">
              <button
                type="button"
                @click="$emit('close-modal')"
                class="btn close-btn"
              >
                close
              </button>
              <button class="btn submit-btn" @click.prevent="confirmEMP">
                Submit
              </button>
            </div>
          </form>
        </div>

        <div v-if="formSubmissionState == 'pending'" class="form-confirm">
          <p class="text-center">Are you sure this {{ title }} exist?</p>

          <div class="confirm-location">
            <p>{{ newEMP.toUpperCase() }},{{ this.city }}</p>
          </div>

          <div class="btn-container">
            <button
              class="btn close-btn"
              @click.prevent="formSubmissionState = null"
            >
              no
            </button>
            <button class="btn submit-btn" @click.prevent="submitNewEMP">
              yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </TheModal>
</template>
<style scoped>
.new-location-modal {
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 3rem;
}

h3 {
  color: #87c76e;
  font-size: 1.5rem;
}

.container-head {
  padding: 1rem 1.5rem;
  border-bottom: 0.5px solid #cbcbcb;
}

.modal-body {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 2rem;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  /* margin: auto; */
  padding: 1rem 2rem;
}

.submit-btn {
  background-color: var(--green-color);
}

.close-btn {
  background-color: transparent;
  border: 1px solid #c4c4c4;
  color: #000000;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;
}

.form-body {
  margin-top: 3rem;
}

.form-body form > * + * {
  margin-top: 1rem;
}

.form-confirm {
  margin-top: 3rem;
}
.form-confirm p {
  text-align: center;
}

.confirm-location {
  background: #efefef;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 2rem 1rem;
  margin-top: 1rem;
}

.confirm-location p {
  text-align: center;
  font-weight: bolder;
}

@media screen and (min-width) {
  .form-body form > * + * {
    margin-top: 1rem;
  }
}
</style>
