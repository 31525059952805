<script>
import FormInput from "./FormInput.vue";
import FormSelect from "./FormSelect.vue";
import TheModal from "./UI/TheModal.vue";

import addLocation from "../services/addLocation";

export default {
  emits: ["confirm-modal", "close-modal"],
  data() {
    return {
      formSubmissionState: null,
      state: null,
      location: ""
    };
  },
  props: ["states"],
  components: { TheModal, FormSelect, FormInput },
  methods: {
    verifyForm() {
      if (!this.state || !this.location) {
        this.$toast.error("Please select state and location");
        return;
      }
      this.formSubmissionState = "pending";
    },

    submitHandler() {
      let body = {
        location: this.location,
        stateid: this.stateId
      };

      console.log(body);

      addLocation.registerLocation(JSON.stringify(body)).then(data => {
        if (data.error) {
          console.log(data.error);
          this.$toast.error("Unable to add new locations");
        } else {
          this.$toast.success("New location added");
          this.$emit("close-modal");
        }
      });
    }
  },
  computed: {
    stateId() {
      return this.states.filter(el => el.name == this.state)[0].id;
    }
  },
  watch: {}
};
</script>
<template>
  <TheModal>
    <div class="new-location-modal">
      <div class="container-head">
        <h3>Add new location</h3>
      </div>

      <div class="modal-body">
        <div class="form-body" v-if="formSubmissionState == null">
          <form>
            <FormSelect
              title="State"
              :value="state"
              @select-option="state = $event"
            >
              <option disabled selected>Select state</option>

              <option
                v-for="option in states"
                :key="option.id"
                :value="option.name"
                >{{ option.name }}</option
              >
            </FormSelect>
            <FormInput
              title="Location"
              :value="location"
              @input="location = $event"
            />

            <div class="btn-container">
              <button
                class="btn close-btn"
                @click.prevent="$emit('close-modal')"
              >
                close
              </button>
              <button class="btn submit-btn" @click.prevent="verifyForm">
                Submit
              </button>
            </div>
          </form>
        </div>

        <div v-if="formSubmissionState == 'pending'" class="form-confirm">
          <p class="text-center">Are you sure this location exist?</p>

          <div class="confirm-location">
            <p>{{ state }}, {{ this.location }}</p>
          </div>

          <div class="btn-container">
            <button
              class="btn close-btn"
              @click.prevent="formSubmissionState = null"
            >
              no
            </button>
            <button class="btn submit-btn" @click.prevent="submitHandler">
              yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </TheModal>
</template>
<style scoped>
.new-location-modal {
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 3rem;
}

h3 {
  color: #87c76e;
  font-size: 1.5rem;
}

.container-head {
  padding: 1rem 1.5rem;
  border-bottom: 0.5px solid #cbcbcb;
}

.modal-body {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 2rem;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  /* margin: auto; */
  padding: 1rem 2rem;
}

.submit-btn {
  background-color: var(--green-color);
}

.close-btn {
  background-color: transparent;
  border: 1px solid #c4c4c4;
  color: #000000;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 0;
}

.form-body {
  margin-top: 3rem;
}

.form-body form > * + * {
  margin-top: 1rem;
}

.form-confirm {
  margin-top: 3rem;
}
.form-confirm p {
  text-align: center;
}

.confirm-location {
  background: #efefef;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 2rem 1rem;
  margin-top: 1rem;
}

.confirm-location p {
  text-align: center;
  font-weight: bolder;
}

@media screen and (min-width) {
  .form-body form > * + * {
    margin-top: 1rem;
  }
}
</style>
